import React from "react";
import Logo from "../../assets/images/logo.png";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../Components/forms/Input";
import { useDispatch } from "react-redux";
import {
  ForgetPasswordOtp,
  SendEmailOtp,
  storeEmail,
} from "../../Redux/auth/authSlice";
import { SideText } from "../Login";
const schema = Yup.object().shape({
  email_number: Yup.string()
    .required("Email is a required field")
    .email("Invalid email"),
  // .matches(/^(?:\d{10}|\w+@\w+\.\w{2,3})$/, {
  //   message: "Please enter valid Email or Phone number",
  // }),
});

export default function ForgotPassword(props) {
  const { setComponentValue } = props;
  const dispatch = useDispatch();
  localStorage.clear();

  return (
    <div className="py-5 container-background min-h-screen sm:pl-[75px] md:pl-[147px] md:pr-[77px]">
      <img src={Logo} alt="logo" />
      <div className="flex items-center mt-20 justify-start">
        <SideText />
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
              FORGOT PASSWORD
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">
              <Formik
                validationSchema={schema}
                initialValues={{ email_number: "" }}
                onSubmit={(values) => {
                  const { email_number } = values;
                  if (email_number.includes("@")) {
                    dispatch(storeEmail(email_number));
                    dispatch(ForgetPasswordOtp({ email_number }))
                      .unwrap()
                      .then((res) => {
                        if (res.status_code === 200) {
                          setComponentValue(2);
                        }
                      })
                      .catch((err) => {});
                  } else {
                    // dispatch(SendNumberOtp({ email_number }))
                    //   .unwrap()
                    //   .then((res) => {
                    //     if (res.status_code === 200) {
                    //       setComponentValue(5);
                    //     }
                    //   })
                    //   .catch((err) => {
                    //   });
                  }
                }}
              >
                {({ handleSubmit }) => (
                  <form
                    className="space-y-6"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="mt-1">
                      <Input
                        id="email_number"
                        name="email_number"
                        type="text"
                        autoComplete="email_number"
                        placeHolder="Enter email address"
                        className="text-center"
                        hideLabel={true}
                      />
                    </div>
                    <div className="bg-gradient-to-r from-[#16E8FF] to-[#E900FF] p-0.5 rounded-[57px]">
                      <button
                        type="submit"
                        className="font-ibm text-base bg-white hover:bg-transparent font-semibold  py-2 px-4 hover:border-transparent hover:text-white rounded-[57px] w-full"
                      >
                        Next
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
