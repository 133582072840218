import React from "react";
import Logo from "../../assets/images/logo.png";
import { Formik } from "formik";
import * as Yup from "yup";
import Input from "../../Components/forms/Input";
import { useDispatch } from "react-redux";
import { ChangePassword } from "../../Redux/auth/authSlice";
import { SideText } from "../Login";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
const schema = Yup.object().shape({
  password: Yup.string()
    .required("New Password is a required field")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/, {
      message:
        "Password must be atleast 6 characters including both uppercase , lowercase ,      symbols like @ , _ , * , # and numbers",
    }),
  confirm_password: Yup.string()
    .required("Confirm Password is a required field")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords did not match"
      ),
    }),
});
export default function ResetPassword(props) {
  const { setComponentValue } = props;
  const dispatch = useDispatch();
  const [values1, setValues1] = React.useState({
    showPassword1: false,
  });
  const [values2, setValues2] = React.useState({
    showPassword2: false,
  });
  const handleClickShowPassword1 = () => {
    setValues1({
      ...values1,
      showPassword1: !values1.showPassword1,
    });
  };
  const handleClickShowPassword2 = () => {
    setValues2({
      ...values2,
      showPassword2: !values2.showPassword2,
    });
  };
  const passwordObj = [
    {
      id: "password",
      name: "password",
      inputType: "password",
      label: "Password",
      autoComplete: "password",
      placeHolder: "New Password",
      icon1: <EyeOutlined />,
      icon2: <EyeInvisibleOutlined />,
      iconsValue: values1.showPassword1,
      handleClickShowPassword: handleClickShowPassword1,
      maxLength: "100",
    },
    {
      id: "confirm_password",
      name: "confirm_password",
      inputType: "password",
      label: "Confirm Password",
      autoComplete: "confirm_password",
      placeHolder: "Confirm Password",
      icon1: <EyeOutlined />,
      icon2: <EyeInvisibleOutlined />,
      iconsValue: values2.showPassword2,
      handleClickShowPassword: handleClickShowPassword2,
      maxLength: "100",
    },
  ];
  return (
    <div className="py-5 container-background min-h-screen  sm:pl-[75px] md:pl-[147px] md:pr-[77px]">
      <img src={Logo} alt="logo" />
      <div className="flex items-center justify-start">
        <SideText />
        <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="mt-6 text-center text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
              RESET PASSWORD
            </h2>
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="py-8 px-4 sm:rounded-lg sm:px-10">
              <Formik
                validationSchema={schema}
                initialValues={{ password: "", confirm_password: "" }}
                onSubmit={(values) => {
                  let dictObj = values;
                  delete dictObj.confirm_password;
                  dispatch(ChangePassword(dictObj))
                    .unwrap()
                    .then((res) => {
                      if (res.status_code === 200) {
                        localStorage.clear();
                        setComponentValue(4);
                      }
                    });
                }}
              >
                {({ handleSubmit }) => (
                  <form
                    className="space-y-6"
                    noValidate
                    onSubmit={handleSubmit}
                  >
                    <div className="mt-1">
                      {passwordObj.map((item) => (
                        <>
                          <Input
                            {...item}
                            className="text-left px-6"
                            hideLabel={true}
                          />
                        </>
                      ))}
                    </div>
                    <div className="bg-gradient-to-r from-[#16E8FF] to-[#E900FF] p-0.5 rounded-[57px]">
                      <button
                        type="submit"
                        className="font-ibm text-base bg-white hover:bg-transparent font-semibold  py-2 px-4 hover:border-transparent hover:text-white rounded-[57px] w-full"
                      >
                        Reset
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
