import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ThemeContext } from ".";
import Button from "../../Components/forms/Button";
import CommonTextArea from "../../Components/forms/CommonTextArea";
import Input from "../../Components/forms/Input";
import { SignUp, storeEmail, storePhone } from "../../Redux/auth/authSlice";
import ReportIssueIcon from "../../assets/Icons/ReportIssueIcon";
import Logo from "../../assets/images/logo.png";
import CommonPhone from "../../Components/forms/CommonPhone";
import CommonDatePicker from "./../../Components/forms/CommonDatePicker";

export const phoneRegExp = /^[0-9\b]+$/;
export const emailRegExp = /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/gi;
export const charsOnly = /^[a-zA-Z\s]*$/;
export const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{6,})/;
export const usernameRegExp = /^[a-z][a-z0-9_.]{4,14}[a-z0-9]$/;
export const capitalizeWords = (str) => {
  return str
    ?.toLowerCase()
    ?.split(" ")
    ?.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    ?.join(" ");
};
const schema1 = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Email is required")
    .matches(emailRegExp, "Please enter a valid email"),
  phone: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Please enter 0-9 numbers only")
    .min(10, "Please enter a valid 10-digit phone number."),
  first_name: Yup.string()
    .required("First name is required")
    .matches(charsOnly, "Please enter characters only"),
  last_name: Yup.string()
    .required("Last name is required")
    .matches(charsOnly, "Please enter characters only"),
  password: Yup.string()
    .required("Password is required")
    .matches(
      passwordRegExp,
      "Password must be at least 6 characters including 1 uppercase, 1 lowercase, 1 number and 1 symbol"
    ),
  confirm_password: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password")], "Passwords did not match"),
});
const schema2 = Yup.object().shape({
  username: Yup.string()
    .required("Username is required")
    .matches(/^[a-z][a-z0-9_.]{4,14}[a-z0-9]$/, {
      message:
        "Username must start with a letter. Allowed characters are a-z (only lower case), numbers(0-9), dots (.) and underscores (_). Username must be between 6 and 16 characters long",
    }),
  date_of_birth: Yup.date()
    .required("Date of birth is required")
    .test(
      "date_of_birth",
      "Age should be greater than 13",
      function (value, ctx) {
        const dob = new Date(value);
        const validDate = new Date();
        const valid = validDate.getFullYear() - dob.getFullYear() >= 13;
        return !valid ? ctx.createError() : valid;
      }
    ),
  social_link: Yup.string()
    .required("Social link is required")
    .url("Invalid URL link"),
  about: Yup.string().required("About is required"),
  bio: Yup.string().required("Bio is required "),
});
export default function Signup(props) {
  const navigate = useNavigate();
  const { setComponentValue } = props;
  const [showField, setshowField] = useState(true);
  const [info, setinfo] = useContext(ThemeContext);
  const [data, setdata] = useState("");
  const dispatch = useDispatch();
  const [values1, setValues1] = React.useState({
    showPassword1: false,
  });
  const [values2, setValues2] = React.useState({
    showPassword2: false,
  });
  const handleClickShowPassword1 = () => {
    setValues1({
      ...values1,
      showPassword1: !values1.showPassword1,
    });
  };
  const handleClickShowPassword2 = () => {
    setValues2({
      ...values2,
      showPassword2: !values2.showPassword2,
    });
  };

  const signupObj = [
    {
      id: "first_name",
      name: "first_name",
      inputType: "text",
      label: "First Name",
      autoComplete: "first_name",
      placeHolder: "First Name",
      textCapital: "capitalize",
    },
    {
      id: "last_name",
      name: "last_name",
      inputType: "text",
      label: "Last Name",
      autoComplete: "last_name",
      placeHolder: "Last Name",
      textCapital: "capitalize",
    },
    {
      id: "email",
      name: "email",
      inputType: "email",
      label: "Email",
      autoComplete: "email",
      placeHolder: "Email",
    },
    {
      id: "phone",
      name: "phone",
      inputType: "text",
      autoComplete: "phone",
      placeHolder: "Phone Number",
      maxLength: 10,
    },

    {
      id: "password",
      name: "password",
      inputType: "password",
      label: "Password",
      autoComplete: "password",
      placeHolder: "Password",
      icon1: <EyeOutlined />,
      icon2: <EyeInvisibleOutlined />,
      iconsValue: values1.showPassword1,
      handleClickShowPassword: handleClickShowPassword1,
      maxLength: 50,
    },
    {
      id: "confirm_password",
      name: "confirm_password",
      inputType: "password",
      label: "Confirm Password",
      autoComplete: "confirm_password",
      placeHolder: "Confirm Password",
      icon1: <EyeOutlined />,
      icon2: <EyeInvisibleOutlined />,
      iconsValue: values2.showPassword2,
      handleClickShowPassword: handleClickShowPassword2,
      maxLength: 50,
    },
    {
      id: "date_of_birth",
      name: "date_of_birth",
      inputType: "date",
      label: "Date of Birth",
      autoComplete: "date_of_birth",
      placeHolder: "Select Date",
    },
    {
      id: "username",
      name: "username",
      inputType: "text",
      label: "Username",
      autoComplete: "username",
      placeHolder: "Username",
      maxLength: 16,
    },
    {
      id: "social_link",
      name: "social_link",
      inputType: "text",
      label: "Mention Social Link",
      autoComplete: "social_link",
      placeHolder: "Mention Social Link",
    },
    {
      id: "about",
      name: "about",
      inputType: "text",
      label: "Podcast",
      autoComplete: "about",
      placeHolder: "What the podcast would be about",
    },
    {
      name: "bio",
      inputType: "text",
      maxLength: "300",
      placeHolder: "Write a bio",
    },
  ];
  return (
    <>
      <div className="py-5 container-background min-h-screen  sm:pl-[75px] md:pl-[147px] md:pr-[77px]">
        <div className="flex justify-between">
          <img src={Logo} alt="logo" />
          <div
            onClick={() => navigate("/report-issue")}
            className="mr-12 mt-2 cursor-pointer reportissue"
          >
            <ReportIssueIcon />
          </div>
        </div>
        <div className="flex items-center justify-start">
          <div className="flex-1">
            <h1 className="heading w-full font-schmal relative">
              <div className="absolute right-0 top-12 h-full border-r-4 border-[#0000001a]"></div>
              THE PREMIER NETWORK <br />
              FOR YOUTH ATHLETES
            </h1>
          </div>
          <div className="flex-1 flex flex-col justify-center py-12 sm:px-6 lg:px-8 ">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <h2 className="mt-6 text-center text-black text-6xl leading-[98%] tracking-[0.06em] font-schmal">
                APPLY FOR AN ACCOUNT
              </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="py-8 px-4 sm:rounded-lg sm:px-10">
                <Formik
                  validationSchema={schema1}
                  initialValues={{
                    first_name: "",
                    last_name: "",
                    email: "",
                    password: "",
                    confirm_password: "",
                    phone: "",
                    country_code: "+91",
                  }}
                  onSubmit={(values) => {
                    setdata(values);
                    setshowField(false);
                  }}
                >
                  {({ handleSubmit, values, setFieldValue }) => (
                    <form
                      className="space-y-6"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <div className="mt-1">
                        {signupObj.map((item, index) => (
                          <>
                            {showField &&
                              index <= 5 &&
                              (item?.name === "phone" ? (
                                <CommonPhone
                                  {...item}
                                  key={index}
                                  codeName={"country_code"}
                                  codeValue={values.country_code}
                                  setFieldValue={setFieldValue}
                                  pCss="2px"
                                />
                              ) : (
                                <Input
                                  {...item}
                                  className="text-left px-6"
                                  hideLabel={true}
                                />
                              ))}
                          </>
                        ))}
                      </div>
                      {showField ? (
                        <div className="rounded-[57px] flex gap-5">
                          <Button
                            className="border-red-500 w-[170px] text-black-500 hover:bg-red-700 "
                            title="Back To Login"
                            onClick={() => navigate("/login")}
                            type="button"
                            btnType="filled"
                          />
                          <Button
                            className="border-red-500 w-[170px] text-black-500 hover:bg-red-700 "
                            title="Next"
                            type="submit"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </form>
                  )}
                </Formik>
                {/* Second Page */}
                <Formik
                  validationSchema={schema2}
                  initialValues={{
                    username: "",
                    date_of_birth: "",
                    social_link: "",
                    about: "",
                    bio: "",
                  }}
                  onSubmit={(values) => {
                    let dictObj = {
                      ...values,
                      ...data,
                      date_of_birth: moment(values.date_of_birth.$d).format(
                        "YYYY-MM-DD"
                      ),
                    };
                    dictObj.first_name = capitalizeWords(data.first_name);
                    dictObj.last_name = capitalizeWords(data.last_name);
                    const { email, phone } = dictObj;
                    dispatch(storeEmail(email));
                    dispatch(storePhone(phone));
                    dispatch(SignUp(dictObj))
                      .unwrap()
                      .then((res) => {
                        if (res.status_code === 200) {
                          let result = {
                            user_uuid: res.data.user_uuid,
                          };
                          localStorage.setItem("user", JSON.stringify(result));
                          res?.data?.is_registration_complete
                            ? setComponentValue(5)
                            : res?.data?.is_email_verified &&
                              !res?.data?.is_phone_verified
                            ? setComponentValue(3)
                            : setComponentValue(2);
                          setinfo(dictObj);
                        }
                      })
                      .catch((error) => {});
                  }}
                >
                  {({ handleSubmit, errors, setFieldValue, values }) => (
                    <form
                      className="space-y-6"
                      noValidate
                      onSubmit={handleSubmit}
                    >
                      <div className="mt-1">
                        {signupObj.map((item, index) => (
                          <>
                            {!showField && index > 5 ? (
                              item?.name === "bio" ? (
                                <CommonTextArea
                                  {...item}
                                  className="px-6 rounded-none"
                                />
                              ) : item.name === "date_of_birth" ? (
                                <CommonDatePicker
                                  {...item}
                                  setFieldValue={setFieldValue}
                                  key={index}
                                  hideLabel={true}
                                />
                              ) : (
                                <Input
                                  {...item}
                                  className="text-left px-6"
                                  hideLabel={true}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </>
                        ))}
                      </div>
                      {!showField && (
                        <div>
                          <div className="flex items-center gap-5 justify-start">
                            <Button
                              className="border-red-500 w-[170px] text-black-500 hover:bg-red-700"
                              title="Back"
                              type="button"
                              btnType="filled"
                              onClick={() => setshowField(true)}
                            />
                            <Button
                              type="submit"
                              className={`w-[170px] `}
                              title={"Continue"}
                            />
                          </div>
                          <div className="flex justify-center fontstyle mt-4">
                            Already Have An Account?
                            <Link
                              to="/login"
                              style={{
                                color: "#777EFF",
                                textDecoration: "none",
                              }}
                              className="text-sm leading-[22px] underline ml-1"
                            >
                              Sign In
                            </Link>
                          </div>
                        </div>
                      )}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
